<!--
 * @Author: Dyf
 * @Date: 2023-11-07 11:48:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-27 17:03:39
 * @Description: 素质课程
-->
<style lang="scss" scoped>
.quality-course {
  @include innerPage($p: 24px 0 0);
  @include pageTitle(0);
  @include pageTab_school;
  @include pageFoot;
  .page-tab {
    margin-top: 20px;
    .sort-by {
      margin-left: auto;
      @include flexBox;
      li {
        cursor: pointer;
        color: #484848;
        @include flexBox(center);
        & + li {
          margin-left: 24px;
        }
        &.cur {
          color: #6340c8;
        }
      }
      .sort {
        display: inline-flex;
        font-size: 18px;
        color: rgba($color: #303030, $alpha: 0.51);

        i:last-child {
          margin-left: -11px;
        }

        &.asc i:last-child {
          color: #6340c8;
        }

        &.desc i:first-child {
          color: #6340c8;
        }
      }
    }
  }
  .course-container {
    width: 100%;
    height: calc(100% - 116px);
    box-sizing: border-box;
    padding-top: 20px;
    flex-direction: column;
    position: relative;
    @include flexBox($align: flex-start);
  }
  .course-inner {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    .course-list {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      flex-wrap: wrap;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox($align: flex-start);
      .course-item {
        width: calc((100% - 60px) / 4);
        height: 342px;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        cursor: default;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(4n) {
          margin-right: 0;
        }

        .el-image {
          width: 100%;
          height: 218px;
        }

        .title {
          width: calc(100% - 30px);
          height: 56px;
          line-height: 56px;
          color: #3c3b3b;
          border-bottom: 1px solid #ececec;
          margin: 0 auto;
        }

        .target {
          width: calc(100% - 30px);
          height: 64px;
          margin: 0 auto;
          box-sizing: border-box;
          @include flexBox(space-between);

          .person {
            color: #7a7a7a;
            .pf_bold {
              font-size: 16px;
              color: #6340c8;
            }
          }

          .price {
            font-size: 14px;
            color: #f66478;
            margin-left: auto;
            margin-right: 10px;
            .pf_bold {
              font-size: 18px;
            }
          }
        }

        &:hover {
          box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15),
            5px 5px 27px -10px rgba(108, 78, 203, 0.15);
        }
      }
      .empty {
        width: 375px;
      }
    }
  }
  .course-head {
    height: 44px;
    margin-bottom: 20px;
    flex-shrink: 0;
    @include flexBox(space-between);

    .course-type {
      height: 42px;
      @include flexBox;

      li {
        min-width: 104px;
        height: 100%;
        position: relative;
        margin-right: 15px;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 18px;

        p {
          width: 100%;
          height: 100%;
          color: #3d3d3d;
          border-radius: 10px;
          background: #fff;
          text-align: center;
          line-height: 42px;
          position: relative;
          z-index: 2;
        }

        &::before {
          content: attr(text);
          width: 100%;
          height: 100%;
          border-radius: 10px;
          color: #fff;
          background: #6340c8;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          transform: scale(0);
          z-index: 3;
          text-align: center;
          line-height: 40px;
          border: 1px solid #6340c8;
          box-sizing: border-box;
          @include defaultAni;
        }

        &::after {
          content: "";
          border: solid transparent;
          border-width: 8px 8px 0 8px;
          border-top-color: #6340c8;
          position: absolute;
          bottom: 0;
          left: 50%;
          opacity: 0;
          transform: translateX(-50%);
          z-index: 1;
          @include defaultAni;
        }

        &.current,
        &:not(.current):hover {
          p {
            color: transparent;
          }

          &::before {
            opacity: 1;
            transform: scale(1);
          }
        }

        &:not(.current):hover {
          p {
            color: transparent;
          }

          &::before {
            opacity: 1;
            background: #f0edfa;
            color: #8971d5;
            border-color: #8971d5;
            transform: scale(1);
          }
        }

        &.current::after {
          bottom: -4px;
          opacity: 1;
        }
      }
    }
  }
  .custom-foot {
    flex-shrink: 0;
  }
}
@media screen and (max-width: 1200px) {
  .quality-course {
    @include innerPage($p: 18px 0 0);
    .course-container {
      height: calc(100% - 104px);
      padding-top: 16px;
    }
    .course-head {
      height: 32px;
      margin-bottom: 16px;
      .course-type {
        height: 30px;
        li {
          min-width: 72px;
          padding: 0 8px;
          p {
            line-height: 30px;
            border-radius: 6px;
          }
          &::before {
            line-height: 30px;
            border-radius: 6px;
          }
        }
      }
    }
    .course-inner .course-list {
      .course-item {
        width: calc((100% - 30px) / 3);
        height: 322px;
        margin-right: 15px;
        margin-bottom: 14px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-child(4n) {
          margin-right: 15px;
        }
        .title {
          height: 42px;
          line-height: 42px;
        }
        .target {
          height: 56px;
        }
      }
    }
  }
}
</style>
<template>
  <section class="quality-course">
    <div class="page-title">
      <h3>素质课程</h3>
      <div class="search-form">
        <el-form inline :model="searchForm">
          <el-form-item>
            <el-input
              v-model.trim="searchForm.plstu_cur_title"
              maxlength="100"
              placeholder="搜索课程"
              clearable
            />
          </el-form-item>
        </el-form>
        <el-button type="custom_primary" size="medium" @click="searchData"
          >搜索</el-button
        >
      </div>
    </div>
    <!-- 一级菜单分类 -->
    <div class="page-tab">
      <ul class="tabs bold">
        <li
          class="tab"
          v-for="item in courseType"
          :key="item.plstu_cur_cla_id"
          :class="{ current: item.plstu_cur_cla_id == cur_p.plstu_cur_cla_id }"
          @click="checkFirType(item)"
        >
          {{ item.plstu_cur_cla_title }}
        </li>
      </ul>
      <ul class="sort-by">
        <li
          @click="changeSort('plstu_cur_salesnum')"
          :class="{ cur: searchForm.plstu_cur_salesnum }"
        >
          销量最高
          <span
            class="iconfont sort"
            :class="{
              asc: searchForm.plstu_cur_salesnum == 'asc',
              desc: searchForm.plstu_cur_salesnum == 'desc',
            }"
          >
            <i>&#xe655;</i>
            <i>&#xe654;</i>
          </span>
        </li>
        <li
          @click="changeSort('plstu_cur_pricetype')"
          :class="{ cur: searchForm.plstu_cur_pricetype }"
        >
          免费课程
        </li>
        <li
          @click="changeSort('create_time')"
          :class="{ cur: searchForm.create_time }"
        >
          最新
          <span
            class="iconfont sort"
            :class="{
              asc: searchForm.create_time == 'asc',
              desc: searchForm.create_time == 'desc',
            }"
          >
            <i>&#xe655;</i>
            <i>&#xe654;</i>
          </span>
        </li>
      </ul>
    </div>
    <div class="course-container">
      <div class="course-head" v-if="!$isEmpty(cur_p.children)">
        <ul class="course-type">
          <li
            v-for="item in cur_p.children"
            :key="item.plstu_cur_cla_id"
            :class="{
              current: item.plstu_cur_cla_id == cur_c.plstu_cur_cla_id,
            }"
            :text="item.plstu_cur_cla_title"
            @click="checkSecType(item)"
          >
            <p>{{ item.plstu_cur_cla_title }}</p>
          </li>
        </ul>
      </div>
      <div
        class="course-inner"
        :class="{ 'no-data': $isEmpty(listData) }"
        v-loading="dataLoad"
      >
        <ul class="course-list" v-show="!$isEmpty(listData)">
          <li
            class="course-item"
            v-for="item in listData"
            :key="item.plstu_cur_id"
          >
            <el-image fit="cover" :src="formatfile(item.plstu_cur_image)" />
            <h5 class="bold title line-text--1st">
              {{ item.plstu_cur_title }}
            </h5>
            <div class="target">
              <p class="person">
                <span class="pf_bold">{{ item.plstu_cur_regnum }}</span>
                <span>人已报名</span>
              </p>
              <p class="price">
                <span v-show="item.plstu_cur_price > 0">￥</span>
                <span class="pf_bold">{{
                  (item.plstu_cur_price > 0 && item.plstu_cur_price) || "免费"
                }}</span>
              </p>
              <el-button
                type="custom_primary"
                size="small"
                @click="
                  $router.push({
                    name: 'QUALITY_EDUCATION_DETAIL',
                    params: { id: item.plstu_cur_id },
                  })
                "
                >购买</el-button
              >
            </div>
          </li>
        </ul>
        <div class="no-data--empty" v-show="$isEmpty(listData) && !dataLoad">
          <img src="@assets/images/no-data3.png" alt="" />
          <p>暂无课程资源哦~</p>
        </div>
      </div>
      <!-- 分页控件 -->
      <div class="custom-foot">
        <customPagination
          :current="listPage.pageIndex"
          :total="listPage.total"
          :size="listPage.pageSize"
          @pageChange="flippingPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { plastucurriculumclassList, plastucurriculumList } from "@api/home";
import customPagination from "@comp/customPagination";
import { formatFile } from "@utils";
export default {
  components: { customPagination },
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      courseType: [], // 课程分类
      cur_p: {}, // 一级导航
      cur_c: {}, // 二级导航
      searchForm: {}, // 搜索表单
      listData: [], // 课程列表数据
      /* 表格分页参数 */
      listPage: {
        pageIndex: 1,
        pageSize: 12,
        total: 0,
      },
      dataLoad: false,
    };
  },
  async created() {
    await this.getTypeTree();
    this.getData();
  },
  methods: {
    /** 获取课程分类 */
    async getTypeTree() {
      let res = await plastucurriculumclassList();
      this.courseType = res.data;
      if (res.data.length) {
        this.cur_p = res.data[0];
        if (res.data[0].children.length) {
          this.cur_c = res.data[0].children[0];
        }
      }
    },
    /** 获取课程列表 */
    async getData() {
      this.dataLoad = true;
      let cla_id = this.cur_c.plstu_cur_cla_id
        ? this.cur_c.plstu_cur_cla_id
        : this.cur_p.plstu_cur_cla_id;
      let params = {
        ...this.searchForm,
        plstu_cur_cla_id: cla_id,
        pageindex: this.listPage.pageIndex,
        pagesize: this.listPage.pageSize,
      };
      let { data: res } = await plastucurriculumList(params);
      this.dataLoad = false;
      this.listPage.total = res.allcount;
      this.listData = res.data;
    },
    /** 翻页 */
    flippingPage(val) {
      this.listData = [];
      this.listPage.pageIndex = val;
      this.getData();
    },
    /** 筛选数据 */
    searchData() {
      this.listData = [];
      this.listPage.pageIndex = 1;
      this.getData();
    },
    /** 切换一级分类 */
    checkFirType(data) {
      this.cur_p = data;
      if (data.children.length) {
        this.cur_c = data.children[0];
      }
      this.searchData();
    },
    /** 切换二级分类 */
    checkSecType(data) {
      this.cur_c = data;
      this.searchData();
    },
    /**
     * 切换数据排序
     * @param {string} el 排序根据
     */
    changeSort(el) {
      let sort = "";
      if (el == "plstu_cur_pricetype") {
        if (!this.searchForm[el]) sort = 20;
        else sort = "";
      } else {
        if (!this.searchForm[el]) sort = "desc";
        else if (this.searchForm[el] == "desc") sort = "asc";
        else if (this.searchForm[el] == "asc") sort = "";
      }

      this.searchForm = { plstu_cur_title: this.searchForm.plstu_cur_title };
      if (sort) {
        this.searchForm[el] = sort;
      }
      this.getData();
    },
  },
};
</script>